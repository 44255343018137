import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import VueNumber from "vue-number-animation";
import VueMeta from "vue-meta";
/* Form Validation */
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import {
  required,
  email,
  alpha_spaces,
  numeric,
} from "vee-validate/dist/rules";
extend("email", {
  ...email,
  message: "Por favor ingrese un email válido",
});
extend("required", {
  ...required,
  message: "Este campo es requerido",
});
extend("alpha_spaces", {
  ...alpha_spaces,
  message: "Por favor, ingrese solo letras",
});
extend("numeric", {
  ...numeric,
  message: "Por favor, ingrese solo números",
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
/* --- */
Vue.config.productionTip = false;
Vue.use(VueNumber);

Vue.use(VueMeta);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
