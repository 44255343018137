import Vue from "vue";
import VueRouter from "vue-router";
import VueTimeline from "@growthbunker/vuetimeline";

const Home = () => import("@/views/Home");
const Ecosistema = () => import("@/views/Ecosistema");
const Vision = () => import("@/views/Vision");
const Nosotros = () => import("@/views/Nosotros");
const Contacto = () => import("@/views/Contacto");

Vue.use(VueRouter);
Vue.use(VueTimeline);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/ecosistema",
    name: "Ecosistema",
    component: Ecosistema,
  },
  {
    path: "/vision",
    name: "Vision",
    component: Vision,
  },
  {
    path: "/quienessomos",
    name: "Nosotros",
    component: Nosotros,
  },
  {
    path: "/contacto",
    name: "Contacto",
    component: Contacto,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
